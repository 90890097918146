import { Accordion, CloseButton } from '@mantine/core'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'

const ApplicationAccordionItem = ({
  value,
  title,
  subTitle = '',
  hasErrors,
  isCurrent,
  showDelete = true,
  handleDelete = () => {},
  children,
}) => {
  return (
    <Accordion.Item value={value}>
      <Accordion.Control>
        <div className="flex flex-row items-center justify-between text-sm font-semibold text-doubleNickel-gray-600">
          <div className="flex flex-row items-center gap-4">
            <div className="flex flex-col">
              {title}
              <div className="text-xs font-medium text-doubleNickel-gray-500">
                {subTitle}
              </div>
              {hasErrors && (
                <div className="text-xs font-medium text-doubleNickel-error-500">
                  You have missing fields
                </div>
              )}
            </div>
          </div>

          {isCurrent ? (
            <StyledBadge color="green">Current</StyledBadge>
          ) : (
            <CloseButton
              className={showDelete ? 'visible' : 'invisible'}
              onClick={(e) => {
                e.stopPropagation()
                handleDelete()
              }}
            />
          )}
        </div>
      </Accordion.Control>
      <Accordion.Panel>
        <div className="flex w-full flex-col items-center gap-10 py-4 ">
          {children}
        </div>
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default ApplicationAccordionItem
