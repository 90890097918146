import { z } from 'zod'

import { EquipmentClass } from 'src/graphql/types/drivingExperiences'
import { LicenseClass, MexicanLicenseClass } from 'src/graphql/types/licenses'
import { CountryConfig } from 'src/lib/country.utils'
import { unformatPhoneNumber, removeCountryCode } from 'src/lib/phone.utils'
import { formatSsnString } from 'src/lib/ssn.utils'

export const defaultExperience = {
  employmentExperienceId: undefined,
  companyName: '',
  position: 'Truck Driver',
  unemploymentReason: 'n/a',
  reasonForLeaving: '',
  startDate: undefined,
  endDate: undefined,
  current: false,
  dotNumber: '',
  contactName: '',
  email: '',
  phone: '',
  faxNumber: '',
  isUnemployment: false,
  isDriverTerminated: false,
  isDriverSubjectToFMCSRs: true,
  isJobDesignatedAsSafetySensitiveFunctionUnderDOT: true,
  shouldContactEmployer: true,
  address: {
    street: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    startDate: undefined,
    endDate: undefined,
  },
}

export const defaultUnemployment = {
  employmentExperienceId: undefined,
  companyName: 'n/a',
  position: 'n/a',
  unemploymentReason: '',
  reasonForLeaving: 'n/a',
  startDate: undefined,
  endDate: undefined,
  isUnemployment: true,
  isDriverTerminated: false,
  isDriverSubjectToFMCSRs: true,
  isJobDesignatedAsSafetySensitiveFunctionUnderDOT: true,
  shouldContactEmployer: true,
}

export const schema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'First name is required' })
    .max(100, { message: 'First name is too long' }),
  lastName: z
    .string()
    .min(1, { message: 'Last name is required' })
    .max(100, { message: 'Last name is too long' }),
  email: z.string().email({ message: 'Please enter a valid email' }),
  phone: z
    .string()
    .refine(
      (data) => unformatPhoneNumber(removeCountryCode(data)).length === 10,
      {
        message: 'Phone number should have 10 digits',
      }
    ),
  dob: z
    .date({ invalid_type_error: 'This is not a valid date' })
    .refine((data) => data < new Date(), {
      message: 'You must select a date in the past',
    }),
  //https://stackoverflow.com/questions/75913987/validating-dates-with-zod-react-start-date-end-date
  issuingCountry: z.string(),
  // TODO: Add validation for ssn based on issuing country
  ssn: z
    .string()
    .min(11, {
      message: 'It should have minimum of 9 digits',
    })
    .max(11, {
      message: `It can't be more than 11 digits`,
    }),
  availableForWork: z
    .date({ invalid_type_error: 'This is not a valid date' })
    .refine((data) => data > new Date(), {
      message: 'This should be a future date',
    })
    .nullish()
    .or(z.literal('')),
  addresses: z
    .array(
      z
        .object({
          city: z
            .string()
            .min(1, { message: 'City is required' })
            .max(100, { message: 'The name of your city is too long' }),
          state: z
            .string()
            .min(2, { message: 'State is required' })
            .max(50, { message: 'The name of your state is too long' }),
          zipCode: z
            .string()
            .min(5, { message: 'Zipcode is required' })
            .max(100, {
              message: 'This is not a valid zipcode',
            }),
          street: z
            .string()
            .min(1, {
              message: 'Street name is required',
            })
            .max(500, { message: 'Your street name is too long' }),
          country: z
            .string()
            .min(1, { message: 'Country is required' })
            .max(100, { message: 'The name of your country is too long' }),
          startDate: z
            .date({
              invalid_type_error: 'Please enter a valid date (MM/YYYY)',
            })
            .refine((data) => data < new Date(), {
              message: 'You must select a date in the past',
            })
            .refine((data) => data > new Date('01/01/1930'), {
              message: 'Please select a date after 1930',
            }),
          endDate: z
            .date({
              invalid_type_error: 'Please enter a valid date (MM/YYYY)',
            })
            .refine((data) => data < new Date(), {
              message: 'You must select a date in the past',
            })
            .nullish(),
        })
        .refine(
          (data) => data.endDate === null || data.endDate > data.startDate,
          {
            message: 'End date cannot be earlier than start date.',
            path: ['endDate'],
          }
        )
    )
    .optional(),
  equipment: z
    .array(
      z.object({
        type: z.nativeEnum({ ...EquipmentClass }).optional(),
        make: z.string().optional(),
        model: z.string().optional(),
        year: z.coerce
          .number()
          .int()
          .gte(1900, { message: 'Please enter a more recent year.' })
          .lte(2100, { message: 'Please enter a more recent year.' })
          .optional(),
        mileage: z.coerce
          .number()
          .int()
          .gte(0, { message: 'Please enter a valid number of miles.' })
          .lte(10000000, { message: 'Please enter a valid number of miles.' })
          .optional(),
        vin: z.string().optional(),
        weight: z.coerce
          .number()
          .int()
          .gte(0, { message: 'Please enter a valid weight.' })
          .lte(10000000, { message: 'Please enter a valid weight.' })
          .optional(),
        fifthWheelHeightInches: z.coerce
          .number()
          .int()
          .gte(0, { message: 'Please enter a valid height.' })
          .lte(10000000, { message: 'Please enter a valid height.' })
          .optional(),
      })
    )
    .optional(),
  license: z.object({
    licenseNumber: z.string().min(1, { message: 'This is required' }).max(40),
    licenseClass: z.nativeEnum(
      { ...LicenseClass, ...MexicanLicenseClass },
      {
        errorMap: () => {
          return { message: 'This field is required' }
        },
      }
    ),
    expirationDate: z
      .date({ invalid_type_error: 'This is not a valid date' })
      .refine((data) => data > new Date('01/01/1930'), {
        message: 'Please select a date after 1930',
      }),
    medicalExamExpirationDate: z
      .date({ invalid_type_error: 'This is not a valid date' })
      .refine((data) => data > new Date('01/01/1930'), {
        message: 'Please select a date after 1930',
      })
      .nullable(),
    state: z
      .string({
        required_error: 'Please select a state from the dropdown',
      })
      .min(1, { message: 'Please select a state from the dropdown' }),
    // endorsements: z.array(z.nativeEnum(Endorsement)).optional(),
    isLicenseSuspended: z.boolean(),
  }),
  twicCard: z.object({
    cardNumber: z
      .string()
      .length(8, { message: 'The TWIC CIN must be 8 digits long' })
      .nullish()
      .or(z.literal('')),
    expirationDate: z
      .date({ invalid_type_error: 'This is not a valid date' })
      .refine((data) => data > new Date('01/01/1930'), {
        message: 'Please select a date after 1930',
      })
      .nullish()
      .or(z.literal('')),
  }),
  accidents: z.array(
    z.object({
      accidentDate: z
        .date({ invalid_type_error: 'This is not a valid date' })
        .refine((data) => data < new Date(), {
          message: 'You must select a date in the past',
        })
        .refine((data) => data > new Date('01/01/1930'), {
          message: 'Please select a date after 1930',
        }),
      city: z
        .string({
          required_error: 'Please select a city from the dropdown',
        })
        .min(1)
        .max(100),
      state: z.string().min(2).max(100),
      description: z.string().min(1).max(1000),
      isPreventable: z.boolean(),
      isCommercialMotorVehicleInvolved: z.boolean(),
    })
  ),

  employmentExperiences: z.array(
    z
      .object({
        companyName: z
          .string()
          .min(1, { message: 'This is required' })
          .max(100),
        position: z.string().min(1, { message: 'This is required' }).max(100),
        unemploymentReason: z
          .string()
          .min(1, { message: 'This is required' })
          .max(1000),
        reasonForLeaving: z
          .string()
          .min(1, { message: 'This is required' })
          .max(1000),
        isDriverTerminated: z.boolean(),
        isDriverSubjectToFMCSRs: z.boolean(),
        shouldContactEmployer: z.boolean(),
        isJobDesignatedAsSafetySensitiveFunctionUnderDOT: z.boolean(),
        startDate: z
          .date({ invalid_type_error: 'This is not a valid date' })
          .refine((data) => data < new Date(), {
            message: 'You must select a date in the past',
          })
          .refine((data) => data > new Date('01/01/1930'), {
            message: 'Please select a date after 1930',
          }),
        endDate: z
          .date({ invalid_type_error: 'This is not a valid date' })
          .refine((data) => data < new Date(), {
            message: 'You must select a date in the past',
          })
          .nullish(),
      })
      .refine(
        (data) => data.endDate === null || data.endDate >= data.startDate,
        {
          message: 'End date is required and must be after your start date.',
          path: ['endDate'],
        }
      )
  ),
})

export const generateInitialValues = (application) => {
  const issuingCountry = application?.issuingCountry || CountryConfig.US.code
  const ssn = application?.ssn
    ? formatSsnString(issuingCountry, application.ssn)
    : ''

  return {
    firstName: application?.firstName || '',
    middleName: application?.middleName || undefined,
    lastName: application?.lastName || '',
    email: application?.email || '',
    phone: application?.phone || '',
    yearsOfExperience: application?.yearsOfExperience || undefined,
    dob: application?.dob ? new Date(application.dob) : undefined,
    ssn,
    issuingCountry,
    availableForWork: application?.availableForWork
      ? new Date(application.availableForWork)
      : undefined,
    addresses:
      application?.addresses.length > 0
        ? application.addresses.map((add) => {
            return {
              city: add.city,
              state: add.state,
              zipCode: add.zipCode,
              street: add.street,
              country: add.country,
              startDate: add?.startDate
                ? new Date(add.startDate)
                : add.startDate,
              endDate: add?.endDate ? new Date(add.endDate) : add.endDate,
            }
          })
        : [],
    license: {
      licenseNumber: application?.license?.licenseNumber || '',
      licenseClass: application?.license?.licenseClass || '',
      state: application?.license?.state || '',
      country: application?.license?.country || CountryConfig.US.code,
      expirationDate: application?.license?.expirationDate
        ? new Date(application.license.expirationDate)
        : undefined,
      medicalExamExpirationDate: application?.license?.medicalExamExpirationDate
        ? new Date(application.license.medicalExamExpirationDate)
        : undefined,
      endorsements: application?.license?.endorsements || [],
      isLicenseSuspended: application?.license?.isLicenseSuspended || false,
    },
    twicCard: {
      cardNumber: application?.twicCard?.cardNumber,
      expirationDate: application?.twicCard?.expirationDate
        ? new Date(application.twicCard.expirationDate)
        : undefined,
    },
    equipment:
      application?.equipment?.length > 0
        ? application?.equipment.map((equipment) => ({
            type: equipment?.type ?? undefined,
            make: equipment?.make ?? undefined,
            model: equipment?.model ?? undefined,
            vin: equipment?.vin ?? undefined,
            year: equipment?.year ? equipment.year.toString() : undefined,
            weight: equipment?.weight ? equipment.weight.toString() : undefined,
            mileage: equipment?.mileage
              ? equipment.mileage.toString()
              : undefined,
            fifthWheelHeightInches: equipment.fifthWheelHeightInches
              ? equipment.fifthWheelHeightInches.toString()
              : undefined,
          }))
        : [],
    accidents:
      application?.accidents.length > 0
        ? application.accidents.map((accident) => ({
            key: accident.accidentId,
            accidentDate: new Date(accident.accidentDate),
            city: accident?.city || undefined,
            state: accident?.state || undefined,
            country: accident?.country || 'US',
            description: accident?.description || undefined,
            isPreventable: accident?.isPreventable || false,
            isCommercialMotorVehicleInvolved:
              accident?.isCommercialMotorVehicleInvolved || false,
          }))
        : [],
    employmentExperiences:
      application?.employmentExperiences.length > 0
        ? application.employmentExperiences.map((experience) => {
            const defaultItem = experience.isUnemployment
              ? defaultUnemployment
              : defaultExperience
            return {
              employmentExperienceId: experience.employmentExperienceId,
              companyName: experience.companyName ?? defaultItem.companyName,
              isUnemployment: experience?.isUnemployment,
              unemploymentReason:
                experience?.unemploymentReason ??
                defaultItem.unemploymentReason,
              position: experience?.position ?? defaultItem.position,
              reasonForLeaving:
                experience?.reasonForLeaving ?? defaultItem.reasonForLeaving,
              startDate: experience?.startDate
                ? new Date(experience.startDate)
                : experience.startDate,
              endDate: experience?.endDate
                ? new Date(experience.endDate)
                : experience.endDate,
              current: !experience.endDate,
              dotNumber: experience.dotNumber,
              contactName: experience.contactName,
              email: experience.email,
              phone: experience.phone,
              faxNumber: experience.faxNumber,
              isDriverTerminated:
                experience?.isDriverTerminated ??
                defaultItem.isDriverTerminated,
              isDriverSubjectToFMCSRs:
                experience?.isDriverSubjectToFMCSRs ??
                defaultItem.isDriverSubjectToFMCSRs,
              isJobDesignatedAsSafetySensitiveFunctionUnderDOT:
                experience?.isJobDesignatedAsSafetySensitiveFunctionUnderDOT ??
                defaultItem.isJobDesignatedAsSafetySensitiveFunctionUnderDOT,
              shouldContactEmployer:
                experience?.shouldContactEmployer ??
                defaultItem.shouldContactEmployer,
              address: {
                street:
                  experience.address?.street ||
                  defaultExperience.address.street,
                city:
                  experience.address?.city || defaultExperience.address.city,
                state:
                  experience.address?.state || defaultExperience.address.state,
                country:
                  experience.address?.country ||
                  defaultExperience.address.country,
                zipCode:
                  experience.address?.zipCode ||
                  defaultExperience.address.zipCode,
                startDate: experience.address?.startDate
                  ? new Date(experience.address.startDate)
                  : undefined,
                endDate: experience.address?.endDate
                  ? new Date(experience.address.endDate)
                  : undefined,
              },
            }
          })
        : [],
  }
}
