import { useCallback, useState } from 'react'

import { useQuery } from '@apollo/client'
import {
  Box,
  CloseButton,
  Tooltip,
  Button as MantineButton,
} from '@mantine/core'
import dayjs from 'dayjs'

import { navigate, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { GET_EMPLOYEES_ONLY } from 'src/graphql/employees.graphql'
import { replaceRecruitersIdWithName } from 'src/lib/recruiters.utils'
import SMSInput from 'src/pages/ApplicantDetailsPage/tabs/ActivityLogTab/components/ActivityLogInput/SMSInput/SMSInput'

import AttachmentsRow from './Components/AttachmentsRow'

const NotificationCard = ({
  notification,
  updateCompanyAndRedirect,
  markAsRead,
  onReplySMS,
}) => {
  const [isReplying, setIsReplying] = useState(false)
  const [hover, setHover] = useState(false)
  const { currentUser } = useAuth()
  const applicantExists = notification.applicant

  const title = applicantExists
    ? notification.applicant.firstName + ' ' + notification.applicant.lastName
    : notification.title

  const isNotificationFromDifferentCompany =
    notification?.company &&
    currentUser.companyId !== notification?.company?.companyId

  const { data: employees, loading: employeesLoading } = useQuery(
    GET_EMPLOYEES_ONLY,
    {
      variables: {
        filters: {
          roleTypes: 'RECRUITER',
          ...(isNotificationFromDifferentCompany && {
            companyId: notification.company?.companyId,
          }),
        },
      },
    }
  )
  const recruiters = employeesLoading
    ? []
    : employees?.employees?.items?.map((employee) => ({
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.employeeId,
      })) ?? []

  const handleApplicantNavigate = useCallback(
    (straightToApplicant = false) => {
      if (applicantExists) {
        const route = straightToApplicant
          ? routes.applicantDetails({
              id: notification.applicant.applicantId,
              tab: 'application',
            })
          : notification.type === 'ORDER_UPDATE'
          ? routes.applicantDetails({
              id: notification.applicant.applicantId,
              tab: 'documents',
              subtab: 'documents',
            })
          : notification.type === 'NOTE'
          ? routes.applicantDetails({
              id: notification.applicant.applicantId,
              tab: 'activitylog',
              activityType: 'NOTE',
            })
          : notification.type === 'SMS'
          ? routes.applicantDetails({
              id: notification.applicant.applicantId,
              tab: 'activitylog',
              activityType: 'SMS',
            })
          : notification.type === 'TRAINING'
          ? routes.applicantDetails({
              id: notification.applicant.applicantId,
              tab: 'training',
            })
          : routes.applicantDetails({
              id: notification.applicant.applicantId,
              tab: 'application',
            })

        if (isNotificationFromDifferentCompany) {
          updateCompanyAndRedirect(notification.employeeId, route)
        } else {
          navigate(route)
        }
      }
    },
    [applicantExists, notification]
  )

  const onHover = useCallback(() => {
    setHover(true)
  }, [])

  const onLeave = useCallback(() => {
    setHover(false)
  }, [])

  return (
    <div
      className="group relative flex flex-col gap-2 border-b-[1px] border-doubleNickel-gray-200 px-2 py-3 hover:rounded-lg hover:border  hover:bg-doubleNickel-gray-50"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-4">
          <div className="flex flex-row items-center gap-1">
            {new Date(notification.createdAt).getTime() >
              new Date().getTime() - 86400000 && (
              <Tooltip label="New">
                <Box className="mr-1 h-2 w-2 rounded-full bg-doubleNickel-brand-500" />
              </Tooltip>
            )}
            <Tooltip label={title}>
              <Box
                className={`truncate text-sm font-medium text-doubleNickel-gray-900 ${
                  applicantExists &&
                  'hover:cursor-pointer hover:text-doubleNickel-brand-500 hover:underline'
                }`}
                onClick={() => handleApplicantNavigate(true)}
              >
                {title}
              </Box>
            </Tooltip>
            {applicantExists && (
              <div className="text-sm text-doubleNickel-gray-700">
                <div className="flex flex-row gap-1">
                  {notification.type === 'SMS' && ' sent you a '}
                  {notification.type === 'NOTE' && ' has a pending '}
                  {notification.type === 'ORDER_UPDATE' && ' updated an '}
                  {notification.type === 'VOE_RESPONSE' && ' received a '}
                  {notification.type === 'TRAINING' && ' completed a '}

                  <Box
                    className="font-medium text-doubleNickel-gray-900 hover:cursor-pointer hover:text-doubleNickel-brand-500 hover:underline"
                    onClick={() => handleApplicantNavigate()}
                  >
                    {notification.type === 'SMS' && 'message'}
                    {notification.type === 'NOTE' && ' note'}
                    {notification.type === 'ORDER_UPDATE' && 'order'}
                    {notification.type === 'VOE_RESPONSE' && 'VoE response'}
                    {notification.type === 'TRAINING' && ' training'}
                  </Box>
                </div>
              </div>
            )}
          </div>

          <div className="ml-auto flex flex-row items-center gap-4">
            <Tooltip label="Mark as read">
              <CloseButton
                size="sm"
                onClick={() =>
                  markAsRead({
                    variables: { id: notification.notificationId },
                  })
                }
              />
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-row items-center gap-1 text-xs font-medium text-doubleNickel-gray-600">
          {isNotificationFromDifferentCompany && (
            <Tooltip label={`Go to ${notification.company?.companyName}`}>
              <Box
                onClick={() =>
                  updateCompanyAndRedirect(
                    notification.employeeId,
                    routes.dashboard()
                  )
                }
                className="flex flex-row gap-1 hover:cursor-pointer hover:text-doubleNickel-brand-500 hover:underline"
              >
                Sent from {notification.company?.companyName} at
              </Box>
            </Tooltip>
          )}
          <div>{dayjs(notification.createdAt).format('h:mm A')}</div>
        </div>
      </div>
      <div
        className={`text-sm italic text-doubleNickel-gray-700 ${
          hover ? 'line-clamp-none' : 'line-clamp-2'
        }`}
      >
        {employeesLoading
          ? ''
          : replaceRecruitersIdWithName(notification.message, recruiters)}
      </div>
      {notification.type === 'SMS' &&
        notification.activityLog?.documents &&
        notification.activityLog?.documents.length > 0 && (
          <AttachmentsRow
            disabled={isNotificationFromDifferentCompany}
            disabledText="Switch companies to open attachments"
            attachments={notification.activityLog.documents}
            applicantId={notification.applicant?.applicantId}
          />
        )}
      <div className="flex flex-row justify-between">
        {notification.type === 'VOE_RESPONSE' && hover && (
          <div className="mt-3 flex flex-row items-center gap-2">
            {isNotificationFromDifferentCompany ? (
              <>
                <div>
                  <Tooltip
                    label={`This action will switch you to your ${notification.company?.companyName} account`}
                  >
                    <MantineButton
                      size="xs"
                      variant="filled"
                      onClick={() => {
                        const employeeId = notification.employeeId
                        const route = routes.voeResponseDetails({
                          id: notification.applicant.applicantId,
                          tab: 'voe',
                          voeId: notification.voe.voeId,
                        })
                        updateCompanyAndRedirect(employeeId, route)
                      }}
                    >
                      Switch & View
                    </MantineButton>
                  </Tooltip>
                </div>
              </>
            ) : (
              <div>
                <MantineButton
                  size="xs"
                  onClick={() =>
                    navigate(
                      routes.voeResponseDetails({
                        id: notification.applicant.applicantId,
                        tab: 'voe',
                        voeId: notification.voe.voeId,
                      })
                    )
                  }
                  variant="filled"
                >
                  View
                </MantineButton>
              </div>
            )}
          </div>
        )}
        {!isReplying && notification.type === 'SMS' && hover && (
          <div className="mt-3 flex flex-row items-center gap-2">
            {isNotificationFromDifferentCompany ? (
              <>
                <div>
                  <Tooltip
                    label={`This action will switch you to your
                  ${notification.company?.companyName} account`}
                  >
                    <MantineButton
                      size="xs"
                      onClick={() => {
                        const employeeId = notification.employeeId
                        const route = routes.applicantDetails({
                          id: notification.applicant?.applicantId,
                          tab: 'activitylog',
                          activityType: 'SMS',
                        })
                        updateCompanyAndRedirect(employeeId, route)
                      }}
                      variant="filled"
                    >
                      Reply From {notification.company?.companyName}
                    </MantineButton>
                  </Tooltip>
                </div>
              </>
            ) : (
              <div>
                <MantineButton
                  size="xs"
                  onClick={() => setIsReplying(!isReplying)}
                  variant="filled"
                >
                  Reply
                </MantineButton>
              </div>
            )}
          </div>
        )}
      </div>
      {isReplying && (
        <div className="flex flex-row items-end gap-2">
          <div className="flex-1">
            <SMSInput
              applicantId={notification.applicant?.applicantId}
              sendSMS={({ content }) =>
                onReplySMS({
                  notificationId: notification.notificationId,
                  message: content,
                })
              }
            />
          </div>
          <CloseButton
            className="mb-2"
            size="sm"
            onClick={() => setIsReplying(false)}
          />
        </div>
      )}
    </div>
  )
}

export default NotificationCard
